<template>
  <div>
    <div>
      <div class="searchArea">
        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-model:value="inputs.category" placeholder="类型">
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="Jingfang">经方</a-select-option>
          <a-select-option value="Mifang">秘方</a-select-option>
          <a-select-option value="药对">药对</a-select-option>
          <a-select-option value="穴位处方">穴位处方</a-select-option>
        </a-select>
        <a-input v-model:value="inputs.name" placeholder="名称" />
        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>
        <a-button v-if="type == 'Jingfang'" style="margin-left: 10px" type="primary" @click="visible2 = true">
          <template #icon>
            <PlusOutlined />
          </template>
          新增处方
        </a-button>
        <a-button v-else-if="type == '穴位处方'" style="margin-left: 10px" type="primary" @click="visible4 = true">
          <template #icon>
            <PlusOutlined />
          </template>
          新增穴位处方
        </a-button>
      </div>

      <a-table class="ant-table-striped" :columns="columns" :data-source="data"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id"
        :scroll="{ y: 550 }" :pagination="{ pageSize, total, current }" @change="search">

        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span>
                <a @click="getPrescription(record)">详情</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a v-if="type == '中药处方'" @click="editPrescription(record)">修改处方</a>
                <a v-else @click="editAcupoint(record)">修改处方</a>
              </span>
            </div>
            <div class="editable-row-operations flex_sa">
              <span v-if="editableData[record.id]">
                <a @click="save(record.id)">保存</a>
              </span>
              <span v-if="editableData[record.id]">
                <a @click="cancel(record.id)">取消</a>
              </span>
              <span v-else>
                <a @click="edit(record.id)">编辑</a>
              </span>
            </div>
            <div class="editable-row-operations">
              <span>
                <a-popconfirm title="是否确认删除" @confirm="onDelete(record.id)" okText="确认" cancelText="取消">
                  <a>删除</a>
                </a-popconfirm>
              </span>
            </div>

          </div>

        </template>
        <template #formula="{ record, text }">
          <div style="text-align: left;">{{ text }}</div>
        </template>
        <template v-for="col in ['name', 'description', 'source']" #[col]="{ text, record }" :key="col">
          <div>
            <!-- <div>{{record.id}}</div> -->
            <a-input v-if="editableData[record.id]" v-model:value="editableData[record.id][col]"
              @change="changeDataNameInitial(record)" style="margin: -5px 0" />
            <template v-else>{{ text }}</template>
          </div>
        </template>
      </a-table>

    </div>

    <!-- 查看 -->
    <getPrescription v-model="visible" :record="selectedRow" />
    <!-- 新增 -->
    <addPrescription v-model="visible2" />
    <!-- 编辑 -->
    <editPrescription v-model="visible3" :record="selectedRow" />

    <addAcupoint v-model="visible4" />
    <editAcupoint v-model="visible5" :record="selectedRow" />

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search, Update, Delete } from "/src/api/prescription.js";
import getPrescription from '/src/components/prescription/getPrescription.vue';
import addPrescription from '/src/components/prescription/addPrescription.vue';
import editPrescription from '/src/components/prescription/editPrescription.vue';
import addAcupoint from '/src/components/acupoint/addAcupoint.vue';
import editAcupoint from '/src/components/acupoint/editAcupoint.vue';


export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    locale,
    editPrescription,
    addPrescription,
    getPrescription,
    addAcupoint,
    editAcupoint
  },
  name: "处方管理",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      pageSize: 12,
      visible: false,
      visible2: false,
      category: "经方",
      visible3: false,
      visible4: false,
      visible5: false,
      active: undefined,
      id: undefined,
      total: 0,
      current: 1,
      inputs: {
        name: undefined,
        category: "Jingfang"
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "名称",
          key: "name",
          dataIndex: "name",
          width: "25%",
          slots: {
            customRender: "name"
          }
        },
        // {
        //   title: "首字母",
        //   key: "nameInitial",
        //   dataIndex: "nameInitial",
        //   width: "25%",
        //   slots: {
        //     customRender: "nameInitial"
        //   }
        // },
        {
          title: "描述",
          key: "description",
          dataIndex: "description",
          ellipsis: true,
          width: "20%",
          slots: {
            customRender: "description"
          },
        },
        // {
        //   title: "来源",
        //   width: "15%",
        //   key: "source",
        //   dataIndex: "source",
        //   slots: {
        //     customRender: "source"
        //   }
        // },
        {
          title: "处方",
          key: "formula",
          dataIndex: "formula",
          // ellipsis: true,
          // align: "left",
          width: "25%",
          slots: {
            customRender: "formula"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "15%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {
    // 获取处方详情
    getPrescription(record) {
      this.visible = true
      this.selectedRow = { ...record }
      setTimeout(() => {
        console.log(this);
      }, 2000);
    },
    // 打开编辑处方
    editPrescription(record) {
      this.visible3 = true
      this.selectedRow = { ...record }
    },
    editAcupoint(record) {
      this.visible5 = true;
      console.log(record);

      this.selectedRow = { ...record }
    },
    resetForm() {
      this.inputs = {
        name: undefined,
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      if (res.errCode == 0) {
        this.data = this.data.filter(function (item) {
          return item.id !== id;
        });
      } else {
        this.$message.error("删除失败", function () { });
      }

    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;

      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        // console.log("未修改")
        delete this.editableData[id];
        return;
      }
      let res = await Update(this.editableData[id])
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        // console.log(JSON.stringify(this.editableData[id]))
        // console.log(this.editableData[id])
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        category: this.inputs.category,
        type: this.inputs.category == '穴位处方' ? "穴位处方" : "中药处方",
        pageSize: this.pageSize,
      };
      this.type = this.inputs.category
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current;
        this.current = pagination.current;
      }
      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data.records;
      } else {
        this.$message.error("查询失败", function () { });
      }
      // this.data = res
    },
  }
};
</script>